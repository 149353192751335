












































































































import { API_USER_LIST } from "@/configs/Apis";
import formatters from "@/utils/ColumnFormater";
import {
  hasPermission,
  isEmptyOrWhiteSpace,
  showConfirm,
  showSuccess,
  showError,
  showWarning,
} from "@/utils/Common";
import UserForm from "@/views/setting/subviews/UserForm.vue";
import { TableColumn } from "element-ui/types/table-column";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    UserForm,
  },
})
export default class UserList extends Vue {
  queryData: any = { keyword: "", userType: "INNER" };

  addUserFormDialogVisible = false;
  userItem: any = { id: 0 };

  hasSelectedItems = false;

  queryStr = "";
  loadData = false;

  showSearch = false;

  get searchBtnIcon(): string {
    return this.showSearch ? "el-icon-arrow-up" : "el-icon-arrow-down";
  }

  get dataApi() {
    return API_USER_LIST;
  }

  get colFormatters() {
    return formatters;
  }

  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }

  createTableColumns() {
    const columns: Array<TableColumnOption> = [
      { prop: "loginName", display: "账号", width: 200 },
      { prop: "name", display: "姓名", width: 200 },
      { prop: "userType", display: "角色", width: 120 },
      { prop: "email", display: "邮箱", width: 240 },
      { prop: "phone", display: "电话", width: 200 },
      { prop: "disabled", display: "状态" },
      // { prop: "weixinUid", display: "微信UID" }
    ];
    this.applyColumnFormatters(columns);
    this.$store.dispatch("users/setColumns", columns);
  }

  applyColumnFormatters(columns: Array<TableColumnOption>) {
    const fmers = {
      userType: this.colFormatters.formatUserLevel.bind(this),
      disabled: this.colFormatters.formatUserStatus.bind(this),
      phone: this.colFormatters.formatPhone.bind(this),
      email: this.colFormatters.formatEmail.bind(this),
    };
    if (!columns || !columns.length) {
      return;
    }
    columns.forEach((option) => {
      if (!Object.prototype.hasOwnProperty.call(fmers, option.prop)) {
        return;
      }
      option.formatter = (fmers as any)[option.prop];
    });
  }

  reloadData() {
    this.searchData();
  }

  onModuleRegistered() {
    this.$nextTick()
      .then(() => this.createTableColumns())
      .then(() => {
        this.onSearchConditionChange();
      });
  }

  onTextKeyup() {
    ((this.$refs.searchBtn as Vue).$el as HTMLInputElement).focus();
  }

  onSearchConditionChange() {
    const params: { [key: string]: any } = {};
    params.userType = "INNER";
    if (!isEmptyOrWhiteSpace(this.queryData.keyword)) {
      params.keyword = this.queryData.keyword;
    }
    const paramsStr = JSON.stringify(params);
    if (paramsStr === "{}" && this.queryStr.trim() === "") {
      this.queryStr = "{}";
      return;
    }
    if (paramsStr !== this.queryStr.trim()) {
      this.queryStr = JSON.stringify(params);
    }
  }

  searchData() {
    if (this.queryStr.endsWith(" ")) {
      this.queryStr = this.queryStr.trim();
    } else {
      this.queryStr += " ";
    }
  }

  addUser() {
    this.userItem = { id: 0, userType: "" };
    this.addUserFormDialogVisible = true;
  }

  addUserSuccess() {
    this.addUserFormDialogVisible = false;
    this.reloadData();
    this.userItem = { id: 0, userType: "" };
  }

  editUser() {
    const selectedRows = (this.$refs.usergrid as any).getSelectedDataRows();
    if (!selectedRows || selectedRows.length === 0) {
      showWarning("请选择一个用户");
      return;
    }
    this.userItem = JSON.parse(JSON.stringify(selectedRows[0]));
    this.userItem.loginPassword = "";
    this.userItem.phone = "";
    this.userItem.email = "";
    this.addUserFormDialogVisible = true;
  }

  onSelectionChanged(selectedRows: any[]) {
    this.hasSelectedItems = selectedRows.length > 0;
  }

  onRowClick(command: string, data: any, event: MouseEvent) {
    //
  }

  onColumnResize(newWidth: number, column: TableColumn) {
    this.$store.dispatch("users/setColumnWidth", {
      listName: "user",
      column: column.property,
      newWidth,
    });
  }

  onTableDataLoaded() {
    //
  }

  openSearchPanel() {
    this.showSearch = !this.showSearch;
  }

  batchDisableData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.usergrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    showConfirm(`是否确认停用 ${msg}？`).then(() => {
      this.$store
        .dispatch("users/disableUser", params)
        .then(() => {
          showSuccess("停用成功");
          this.reloadData();
        })
        .catch(({ message }) => showError(message));
    });
  }

  batchEnableData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.usergrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    showConfirm(`是否确认启用 ${msg}？`).then(() => {
      this.$store
        .dispatch("users/enableUser", params)
        .then(() => {
          showSuccess("启用成功");
          this.reloadData();
        })
        .catch(({ message }) => showError(message));
    });
  }
}
