










































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { showError, showSuccess, showWarning } from "@/utils/Common";
import {
  MSG_ADD_SUCCESS,
  MSG_INVALID_PAGE,
  MSG_MODIFY_SUCCESS
} from "@/configs/Consts";

@Component
export default class UserForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  userItem!: any;

  modifyPassword = false;
  modifyPhone = false;
  modifyEmail = false;

  get userTypes() {
    const allTypes = this.$store.getters["enums/getEnumsByKey"]("UserType");
    const types: Array<any> = [];
    allTypes.forEach((type: any) => {
      if (type.enumValue !== "CUSTOMER") {
        types.push(type);
      }
    });
    return types;
  }

  isEditUser() {
    return this.userItem && this.userItem.id;
  }

  saveUser() {
    const form = this.$refs.userForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const userData = JSON.parse(JSON.stringify(this.userItem));
      userData.userType = userData.userType || "CUSTOMER";
      this.$store
        .dispatch(
          this.isEditUser() ? "users/updateUser" : "users/addUser",
          userData
        )
        .then(() => {
          this.$emit("update:userItem", {});
          showSuccess(this.isEditUser() ? MSG_MODIFY_SUCCESS : MSG_ADD_SUCCESS);
          this.$emit("data-success");
        })
        .catch(({ message }) => showError(message));
    });
  }
}
